import { Module } from "../constants/type";
import { getMenus } from "../services/localStorage";

class AuthHelper {
    private _authModules: Module[] = [];
    private _authModuleKeys: string[] = [];

    constructor() {
        let auth_menus: Module[] | undefined = getMenus();
        if (auth_menus) {
            this._authModules = auth_menus;
            this._authModuleKeys = this._authModules.map(x => `${x.key.substring(0, 1) !== "/" ? "/" : ""}${x.key}`);
        }
    }

    getAuthModules = (): Module[] => this._authModules;

    getAuthModuleKeys = (): string[] => this._authModuleKeys;

    getRouteItem = (key: string): Module | undefined => this._authModules.find(x => `/${x.key}` === key);

    isAuthorized = (key: string, ifTrue: any = undefined) => {
        if (this._authModuleKeys.indexOf(key) > -1) {
            if (ifTrue !== undefined) {
                return ifTrue;
            }
            return true;
        }
        return false;
    };
}

export default AuthHelper;

export const AuthKeys = {
    ALARM_CENTER_DASHBOARD_VIEW: "/alarm-center/dashboard/view",
    ALARM_CENTER_DASHBOARD_EDIT: "/alarm-center/dashboard/edit",
    ALARM_CENTER_HISTORICAL_VIEW: "/alarm-center/historical/view",
    ALARM_CENTER_HISTORICAL_EDIT: "/alarm-center/historical/edit",
    REPORT_HISTORY_VIEW: "/report/result/view",
    REPORT_HISTORY_EDIT: "/report/result/edit",
    TEMPLATE_VIEW: "/report/template/view",
    TEMPLATE_EDIT: "/report/template/edit",
    SYMBOL_ANALYSIS_SPREAD_ANALYSIA_VIEW: "/report/spreadanalysis/view",
    COMMISSION_RECORD_VIEW: "/report/commissionrecord/view",
    COMMISSION_RECORD_EDIT: "/report/commissionrecord/edit",
    NEGATIVE_EQUITY_RECORD_VIEW: "/report/negativeequityrecord/view",
    NEGATIVE_EQUITY_RECORD_EDIT: "/report/negativeequityrecord/edit",
    RC_LINKED_ACCOUNT_RECORD_VIEW: "/report/linkedaccountrecord/view",
    RC_LINKED_ACCOUNT_RECORD_EDIT: "/report/linkedaccountrecord/edit",
    LINKED_ACCOUNT_QUERY_VIEW: "/report/linkedaccountquery/view",
    PRICE_OUTAGE_VIEW: "/pricemonitor/priceoutage/view",
    HISTORICAL_PRICE_OUTAGE_VIEW: "/pricemonitor/historicalpriceoutage/view",
    MID_PRICE_MONITORING_VIEW: "/pricemonitor/midpricemonitoring/view",
    HISTORICAL_MID_PRICE_MONITORING_VIEW: "/pricemonitor/historicalmidpricemonitoring/view",
    MID_BIAS_PRICE_MONITORING_VIEW: "/pricemonitor/midbias/view",
    MID_BIAS_PRICE_MONITORING_EDIT: "/pricemonitor/midbias/edit",
    OUT_OF_PRICE_VIEW: "/pricemonitor/outofprice/view",
    OUT_OF_PRICE_EDIT: "/pricemonitor/outofprice/edit",
    SPREAD_ALARM_VIEW: "/pricemonitor/spreadalarm/view",
    SPREAD_ALARM_EDIT: "/pricemonitor/spreadalarm/edit",
    PRICE_ANALYSIS_ALARM_VIEW: "/pricemonitor/priceanalysisalarm/view",
    PRICE_ANALYSIS_ALARM_EDIT: "/pricemonitor/priceanalysisalarm/edit",
    MONITOR_TRADE_VIEW: "/realtime/trademonitor/view",
    MONITOR_TRADE_EDIT: "/realtime/trademonitor/edit",
    HISTORICAL_EVENT_SUMMARY_VIEW: "/realtime/historicaleventsummary/view",
    HISTORICAL_EVENT_SUMMARY_EDIT: "/realtime/historicaleventsummary/edit",
    HISTORICAL_EVENT_BATCHES_VIEW: "/realtime/historicaleventbatches/view",
    HISTORICAL_EVENT_BATCHES_EDIT: "/realtime/historicaleventbatches/edit",
    DECAY_ANALYSIS_VIEW: "/realtime/decaychart/view",
    CLOSED_DECAY_ANALYSIS_VIEW: "/realtime/closeddecaychart/view",
    HFT_CHART_VIEW: "/realtime/hftBiti/view",
    TOXIC_ACCOUNT_VIEW: "/realtime/toxicaccount/view",
    TOXIC_ACCOUNTS_VIEW: "/realtime/toxicaccount/view",
    TOXIC_ACCOUNTS_EDIT: "/realtime/toxicaccount/edit",
    SYNDICATE_TRADING_VIEW: "/realtime/syndicateTrading/view",
    SYNDICATE_TRADING_EDIT: "/realtime/syndicateTrading/edit",
    RC_HFT_REPORT_VIEW: "/realtime/hftReport/view",
    RC_HFT_REPORT_EDIT: "/realtime/hftReport/edit",
    RC_PE_ORDER_COMPARE_VIEW: "/realtime/peordercompare/view",
    RC_PE_ORDER_COMPARE_EDIT: "/realtime/peordercompare/edit",
    ANALYSIS_CLIENT_PROFILE_VIEW: "/analysis/clientlist/view",
    ANALYSIS_CLIENT_PROFILE_EDIT: "/analysis/clientlist/edit",
    ANALYSIS_ACCOUNT_PROFILE_VIEW: "/analysis/accountprofile/view",
    ANALYSIS_ACCOUNT_PROFILE_EDIT: "/analysis/accountprofile/edit",
    ANALYSIS_ACCOUNT_WATCHLIST_VIEW: "/analysis/watchlist/view",
    ANALYSIS_ACCOUNT_WATCHLIST_EDIT: "/analysis/watchlist/edit",
    TOXIC_CLIENT_CHECK_EDIT: "/analysis/toxicclientcheck/edit",
    TOXIC_CLIENT_CHECK_VIEW: "/analysis/toxicclientcheck/view",
    PROBLEMATIC_CLIENT_VIEW: "/analysis/problematicClients/view",
    PROBLEMATIC_CLIENT_EDIT: "/analysis/problematicClients/edit",
    PREMARKET_ACCOUNT_VIEW: "/analysis/premarketAccountModel/view",
    WITHDRAWAL_AUDIT_VIEW: "/analysis/withdrawalaudit/view",
    WITHDRAWAL_AUDIT_VIEW_API_LOGS: "/analysis/withdrawalaudit/apilogs/view", //
    WITHDRAWAL_AUDIT_EDIT: "/analysis/withdrawalaudit/edit",
    LP360_VIEW: "/lpmonitor/lp360/view",
    LP360_ALARM_VIEW: "/lpmonitor/alarm/view",
    LP360_ALARM_EDIT: "/lpmonitor/alarm/edit",
    INTRADAY_SUMMARY: "/intradaymonitor/summary/view",
    INTRADAY_SEARCH_VIEW: "/intradaymonitor/search/view",
    INTRADAY_SEARCH_EDIT: "/intradaymonitor/search/edit",
    INTRADAY_ALARM_VIEW: "/intradaymonitor/alarm/view",
    INTRADAY_ALARM_EDIT: "/intradaymonitor/alarm/edit",
    SYSTEM_MONITOR_ISSUES_LOG_VIEW: "/systemmonitor/issueslog/view",
    SYSTEM_MONITOR_ISSUES_LOG_EDIT: "/systemmonitor/issueslog/edit",
    SYSTEM_MONITOR_SERVER_PERFORMANCE_VIEW: "/systemmonitor/serverperformance/view",
    SYSTEM_MONITOR_SERVER_PERFORMANCE_EDIT: "/systemmonitor/serverperformance/edit",
    SYSTEM_MONITOR_VIEW: "/systemmonitor/statusmonitor/view",
    SYSTEM_MONITOR_REJECT_ORDER_VIEW: "/systemmonitor/rejectorder/view",
    SYSTEM_MONITOR_REJECT_ORDER_EDIT: "/systemmonitor/rejectorder/edit",
    SYSTEM_MONITOR_DATA_CENTER_VIEW: "/systemmonitor/datacenter/view",
    DATA_CENTER_EDIT: "/systemmonitor/datacenter/edit",
    ADMIN_TOOLS_TICKET_RESTORATION_VIEW: "/admintools/ticketrestoration/view",
    ADMIN_TOOLS_TICKET_RESTORATION_EDIT: "/admintools/ticketrestoration/edit",
    ADMIN_TOOLS_HUB_SWITCHER_EDIT: "/admintools/hubSwitcher/edit",
    ADMIN_TOOLS_HUB_STATUS_DASHBOARD_VIEW: "/admintools/hubStatusDashboard/view",
    ADMIN_TOOLS_HUB_STATUS_DASHBOARD_EDIT: "/admintools/hubStatusDashboard/edit",
    RISK_TOOLS_SWAPCHARGINGTOOL_VIEW: "/admintools/swapchargingtool/view",
    RISK_TOOLS_SWAPCHARGINGTOOL_EDIT: "/admintools/swapchargingtool/edit",
    RISK_TOOLS_SYMBOLSWAPUPLOAD_VIEW: "/admintools/symbolswapupload/view",
    RISK_TOOLS_SYMBOLSWAPUPLOAD_EDIT: "/admintools/symbolswapupload/edit",
    RISK_TOOLS_GROUPTRANSFERTOOL_VIEW: "/admintools/grouptransfertool/view",
    RISK_TOOLS_GROUPTRANSFERTOOL_EDIT: "/admintools/grouptransfertool/edit",
    RISK_TOOLS_HEDGETOOL_VIEW: "/admintools/hedgetool/view",
    RISK_TOOLS_ORDER_ROUTING_RECORD_VIEW: "/admintools/orderroutingrecord/view",
    RISK_TOOLS_ORDER_ROUTING_RECORD_EDIT: "/admintools/orderroutingrecord/edit",
    RISK_TOOLS_SYMBOL_LEVERAGE_EDIT: "/admintools/symbolleverage/edit",
    RISK_TOOLS_SYMBOL_LEVERAGE_VIEW: "/admintools/symbolleverage/view",
    RISK_TOOLS_CA_DIVIDEND_MAP_UPLOAD_VIEW: "/admintools/cadividendmapupload/view",
    RISK_TOOLS_CA_DIVIDEND_MAP_UPLOAD_EDIT: "/admintools/cadividendmapupload/edit",
    RISK_TOOLS_CA_DIVIDEND_SEPARATE_PROCESS_VIEW: "/admintools/cadividendseparateprocess/view",
    RISK_TOOLS_CA_DIVIDEND_SEPARATE_PROCESS_EDIT: "/admintools/cadividendseparateprocess/edit",
    RISK_TOOLS_ROLLOVER_VIEW: "/admintools/rollover/view",
    RISK_TOOLS_ROLLOVER_EDIT: "/admintools/rollover/edit",
    RISK_TOOLS_HUB_TO_HUB_VIEW: "/admintools/hubtohub/view",
    RISK_TOOLS_HUB_TO_HUB_EDIT: "/admintools/hubtohub/edit",
    CONFIG_DIFF_VIEW: "/admintools/configdiff/view",
    CONFIG_DIFF_EDIT: "/admintools/configdiff/edit",
    CREDIT_TO_BALANCE_UPLOAD_VIEW: "/admintools/credittobalanceupload/view",
    CREDIT_TO_BALANCE_UPLOAD_EDIT: "/admintools/credittobalanceupload/edit",
    CID_VIEW: "/admintools/cid/view",
    CID_EDIT: "/admintools/cid/edit",
    RISK_TOOLS_BALANCE_UPDATE_EDIT: "/admintools/balanceupdate/edit",
    RISK_TOOLS_CONFIG_DIFF_VIEW: "/admintools/configdiff/view",
    RISK_TOOLS_READ_ONLY_VIEW: "/admintools/readOnly/view",
    RISK_TOOLS_READ_ONLY_EDIT: "/admintools/readOnly/edit",
    RC_STATUS_CONFIG_RESTART_VIEW: "/admintools/statusconfigurationrestart/view",
    RC_STATUS_CONFIG_RESTART_EDIT: "/admintools/statusconfigurationrestart/edit",
    RC_MT_CLIENT_SECURITY_VIEW: "/admintools/mtClientSecurity/view",
    RC_MT_CLIENT_SECURITY_EDIT: "/admintools/mtClientSecurity/edit",
    RC_SERVER_ARCHIVING_TOOL_VIEW: "/admintools/serverarchivingtool/view",
    RC_SERVER_ARCHIVING_TOOL_EDIT: "/admintools/serverarchivingtool/edit",
    RC_DIVIDENG_UPLOAD_VIEW: "/admintools/dividendupload/view",
    RC_DIVIDENG_UPLOAD_EDIT: "/admintools/dividendupload/edit",
    RC_ADMIN_FEE_PROCESS_VIEW: "/admintools/adminfeeprocess/view",
    ADMIN_USER_VIEW: "/siteadmin/user/view",
    ADMIN_USER_EDIT: "/siteadmin/user/edit",
    ADMIN_ROLE_PERMISSION_VIEW: "/siteadmin/rolepermission/view",
    ADMIN_ROLE_PERMISSION_EDIT: "/siteadmin/rolepermission/edit",
    ADMIN_SCHEDULER_VIEW: "/siteadmin/schedulers/view",
    ADMIN_SCHEDULER_EDIT: "/siteadmin/schedulers/edit",
    ADMIN_TAG_VIEW: "/siteadmin/tag/view",
    ADMIN_TAG_EDIT: "/siteadmin/tag/edit",
    ADMIN_RISKSCORE_ACCOUNT_VIEW: "/siteadmin/riskscore/account/view",
    ADMIN_RISKSCORE_ACCOUNT_EDIT: "/siteadmin/riskscore/account/edit",
    ADMIN_RISKSCORE_BRAND_VIEW: "/siteadmin/riskscore/brand/view",
    ADMIN_RISKSCORE_BRAND_EDIT: "/siteadmin/riskscore/brand/edit",
    ADMIN_BRANDSERVER_BRAND_VIEW: "/siteadmin/brandserver/brand/view",
    ADMIN_BRANDSERVER_BRAND_EDIT: "/siteadmin/brandserver/brand/edit",
    ADMIN_EXTERNAL_SYSTEM_V2_VIEW: "/siteadmin/brandserver/externalsystem/view",
    ADMIN_EXTERNAL_SYSTEM_V2_EDIT: "/siteadmin/brandserver/externalsystem/edit",
    ADMIN_COMMISSION_SETTING_VIEW: "/siteadmin/brandserver/commissionconfig/view",
    ADMIN_COMMISSION_SETTING_EDIT: "/siteadmin/brandserver/commissionconfig/edit",
    ADMIN_BRIDGE_SETTINGS_VIEW: "/siteadmin/brandserver/bridge/view",
    ADMIN_BRIDGE_SETTINGS_EDIT: "/siteadmin/brandserver/bridge/edit",
    ADMIN_DATA_CENTER_SETTINGS_VIEW: "/siteadmin/brandserver/datacenter/view",
    ADMIN_DATA_CENTER_SETTINGS_EDIT: "/siteadmin/brandserver/datacenter/edit",
    RC_BRIDGE_CONFIG_API_VIEW: "/siteadmin/brandserver/bridgeconfigapi/view",
    RC_BRIDGE_CONFIG_API_EDIT: "/siteadmin/brandserver/bridgeconfigapi/edit",
    ADMIN_SERVER_SETTINGS_VIEW: "/siteadmin/brandserver/server/view",
    ADMIN_SERVER_SETTINGS_EDIT: "/siteadmin/brandserver/server/edit",
    RC_FEATURE_SETTING_VIEW: "/siteadmin/brandserver/featuresetting/view",
    RC_FEATURE_SETTING_EDIT: "/siteadmin/brandserver/featuresetting/edit",
    ADMIN_SERVER_VIEW: "/siteadmin/serverbrand/server/view",
    ADMIN_SERVER_EDIT: "/siteadmin/serverbrand/server/edit",
    ADMIN_BRAND_VIEW: "/siteadmin/serverbrand/brand/view",
    ADMIN_BRAND_EDIT: "/siteadmin/serverbrand/brand/edit",
    ADMIN_SERVER_V2_VIEW: "/siteadmin/serverbrand/server/view",
    ADMIN_SERVER_V2_EDIT: "/siteadmin/serverbrand/server/edit",
    ADMIN_DATA_CENTER_V2_VIEW: "/siteadmin/serverbrand/datacenter/view",
    ADMIN_DATA_CENTER_V2_EDIT: "/siteadmin/serverbrand/datacenter/edit",
    ADMIN_GROUP_PURPOSE_VIEW: "/siteadmin/serverbrand/group-purpose/view",
    ADMIN_GROUP_PURPOSE_EDIT: "/siteadmin/serverbrand/group-purpose/edit",
    ADMIN_SERVER_BRAND_MAPPING_EDIT: "/siteadmin/serverbrand/server-brand-mapping/edit", //bind old module, useless, can remove anytime
    APP_CONFIGURATION_VIEW: "/siteadmin/appconfig/view", //stay here, just for metric and app config old module
    APP_CONFIGURATION_EDIT: "/siteadmin/appconfig/edit", //stay here, just for metric and app config old module
    SYMBOL_ASSET_TYPE_VIEW: "/siteadmin/symbolconfig/assettype/view",
    SYMBOL_ASSET_TYPE_EDIT: "/siteadmin/symbolconfig/assettype/edit",
    CLEAN_SYMBOL_VIEW: "/siteadmin/symbolconfig/cleansymbol/view",
    CLEAN_SYMBOL_EDIT: "/siteadmin/symbolconfig/cleansymbol/edit",
    SYMBOL_MAP_VIEW: "/siteadmin/symbolconfig/symbolmap/view",
    SYMBOL_MAP_EDIT: "/siteadmin/symbolconfig/symbolmap/edit",
    SYMBOL_LIST_MAP_VIEW: "/siteadmin/symbolconfig/symbolinfo/view",
    SYMBOL_LIST_MAP_EDIT: "/siteadmin/symbolconfig/symbolinfo/edit",
    ADMIN_NOTIFICATION_CHANNEL_VIEW: "/siteadmin/notificationchannel/view",
    ADMIN_NOTIFICATION_CHANNEL_EDIT: "/siteadmin/notificationchannel/edit",
    ADMIN_MENUS_VIEW: "/siteadmin/menus/view",
    ADMIN_MENUS_EDIT: "/siteadmin/menus/edit",
    TAGGING_LIST_VIEW: "/tagging/list/view",
    TAGGING_LIST_EDIT: "/tagging/list/edit",

    //Centralized Settings
    CENTRALIZED_SETTINGS_PRICE_ALARM_COLOR_SETTINGS_VIEW: "/siteadmin/pricemonitor/pricealarmcolorsettings/view",
    CENTRALIZED_SETTINGS_PRICE_ALARM_COLOR_SETTINGS_EDIT: "/siteadmin/pricemonitor/pricealarmcolorsettings/edit",
    CENTRALIZED_SETTINGS_PRICE_ALARM_SETTINGS_VIEW: "/siteadmin/pricemonitor/pricealarmsettings/view",
    CENTRALIZED_SETTINGS_PRICE_ALARM_SETTINGS_EDIT: "/siteadmin/pricemonitor/pricealarmsettings/edit",
    CENTRALIZED_SETTINGS_PRICE_ANALYSIS_SETTINGS_VIEW: "/siteadmin/pricemonitor/priceanalysissettings/view",
    CENTRALIZED_SETTINGS_PRICE_ANALYSIS_SETTINGS_EDIT: "/siteadmin/pricemonitor/priceanalysissettings/edit",
    CENTRALIZED_SETTINGS_PRICE_BACKEND_OPT_VIEW: "/siteadmin/pricemonitor/pricebackendopt/view",
    CENTRALIZED_SETTINGS_PRICE_BACKEND_OPT_EDIT: "/siteadmin/pricemonitor/pricebackendopt/view",
    CENTRALIZED_SETTINGS_OUT_OF_PRICE_SETTINGS_VIEW: "/siteadmin/pricemonitor/outofpricesettings/view",
    CENTRALIZED_SETTINGS_OUT_OF_PRICE_SETTINGS_EDIT: "/siteadmin/pricemonitor/outofpricesettings/edit",
    CENTRALIZED_SETTINGS_MID_BIAS_ALARM_SETTINGS_VIEW: "/siteadmin/pricemonitor/midbiasalarmsettings/view",
    CENTRALIZED_SETTINGS_MID_BIAS_ALARM_SETTINGS_EDIT: "/siteadmin/pricemonitor/midbiasalarmsettings/edit",
    CENTRALIZED_SETTINGS_MID_PRICE_SETTINGS_VIEW: "/siteadmin/pricemonitor/midpricesettings/view",
    CENTRALIZED_SETTINGS_MID_PRICE_SETTINGS_EDIT: "/siteadmin/pricemonitor/midpricesettings/edit",
    CENTRALIZED_SETTINGS_SPREAD_ALARM_SETTINGS_VIEW: "/siteadmin/pricemonitor/spreadalarmsettings/view",
    CENTRALIZED_SETTINGS_SPREAD_ALARM_SETTINGS_EDIT: "/siteadmin/pricemonitor/spreadalarmsettings/edit",
    CENTRALIZED_SETTINGS_DATA_CENTER_SETTINGS_VIEW: "/siteadmin/systemmonitor/datacentersettings/view",
    CENTRALIZED_SETTINGS_DATA_CENTER_SETTINGS_EDIT: "/siteadmin/systemmonitor/datacentersettings/edit",
    CENTRALIZED_SETTINGS_ISSUES_LOG_SETTINGS_VIEW: "/siteadmin/systemmonitor/issueslogsettings/view",
    CENTRALIZED_SETTINGS_ISSUES_LOG_SETTINGS_EDIT: "/siteadmin/systemmonitor/issueslogsettings/edit",
    CENTRALIZED_SETTINGS_REJECT_ORDER_SETTINGS_VIEW: "/siteadmin/systemmonitor/rejectordersettings/view",
    CENTRALIZED_SETTINGS_REJECT_ORDER_SETTINGS_EDIT: "/siteadmin/systemmonitor/rejectordersettings/edit",
    CENTRALIZED_SETTINGS_SERVER_PERFORMANCE_SETTINGS_VIEW: "/siteadmin/systemmonitor/serverperformancesettings/view",
    CENTRALIZED_SETTINGS_SERVER_PERFORMANCE_SETTINGS_EDIT: "/siteadmin/systemmonitor/serverperformancesettings/edit",
    CENTRALIZED_SETTINGS_HFT_REPORT_SETTINGS_VIEW: "/siteadmin/trademonitor/hftreportsettings/view",
    CENTRALIZED_SETTINGS_HFT_REPORT_SETTINGS_EDIT: "/siteadmin/trademonitor/hftreportsettings/edit",
    CENTRALIZED_SETTINGS_MR_ALARM_SETTINGS_VIEW: "/siteadmin/mrmonitor/mralarmsettings/view",
    CENTRALIZED_SETTINGS_MR_ALARM_SETTINGS_EDIT: "/siteadmin/mrmonitor/mralarmsettings/edit",
    CENTRALIZED_SETTINGS_MR_GROUP_SETTINGS_VIEW: "/siteadmin/mrmonitor/mrgroupsettings/view",
    CENTRALIZED_SETTINGS_MR_GROUP_SETTINGS_EDIT: "/siteadmin/mrmonitor/mrgroupsettings/edit",
    CENTRALIZED_SETTINGS_INTRADAY_ALARM_SETTINGS_VIEW: "/siteadmin/intradaymonitor/intradayalarmsettings/view",
    CENTRALIZED_SETTINGS_INTRADAY_ALARM_SETTINGS_EDIT: "/siteadmin/intradaymonitor/intradayalarmsettings/edit",
    CENTRALIZED_SETTINGS_BRAND_MANAGEMENT_VIEW: "/siteadmin/brandserver/brand/view",
    CENTRALIZED_SETTINGS_BRAND_MANAGEMENT_EDIT: "/siteadmin/brandserver/brand/edit",
    CENTRALIZED_SETTINGS_BRIDGE_CONDIF_API_CONFIGURATION_VIEW: "/siteadmin/brandserver/bridgeconfigapi/view",
    CENTRALIZED_SETTINGS_BRIDGE_CONDIF_API_CONFIGURATION_EDIT: "/siteadmin/brandserver/bridgeconfigapi/edit",
    CENTRALIZED_SETTINGS_BRIDGE_MANAGEMENT_VIEW: "/siteadmin/brandserver/bridge/view",
    CENTRALIZED_SETTINGS_BRIDGE_MANAGEMENT_EDIT: "/siteadmin/brandserver/bridge/edit",
    CENTRALIZED_SETTINGS_DATA_CENTER_MANAGEMENT_VIEW: "/siteadmin/serverbrand/datacenter/view",
    CENTRALIZED_SETTINGS_DATA_CENTER_MANAGEMENT_EDIT: "/siteadmin/serverbrand/datacenter/edit",
    CENTRALIZED_SETTINGS_EXTERNAL_SYSTEM_MANAGEMENT_VIEW: "/siteadmin/brandserver/externalsystem/view",
    CENTRALIZED_SETTINGS_EXTERNAL_SYSTEM_MANAGEMENT_EDIT: "/siteadmin/brandserver/externalsystem/edit",
    CENTRALIZED_SETTINGS_GROUP_PURPOSE_MANAGEMENT_VIEW: "/siteadmin/serverbrand/group-purpose/view",
    CENTRALIZED_SETTINGS_GROUP_PURPOSE_MANAGEMENT_EDIT: "/siteadmin/serverbrand/group-purpose/edit",
    CENTRALIZED_SETTINGS_NOTIFICATION_CHANNEL_MANAGEMENT_VIEW: "/siteadmin/notificationchannel/view",
    CENTRALIZED_SETTINGS_NOTIFICATION_CHANNEL_MANAGEMENT_EDIT: "/siteadmin/notificationchannel/edit",
    CENTRALIZED_SETTINGS_SERVER_MANAGEMENT_VIEW: "/siteadmin/brandserver/server/view",
    CENTRALIZED_SETTINGS_SERVER_MANAGEMENT_EDIT: "/siteadmin/brandserver/server/edit",
    CENTRALIZED_SETTINGS_CLEAN_SYMBOL_MANAGEMENT_VIEW: "/siteadmin/symbolconfig/cleansymbol/view",
    CENTRALIZED_SETTINGS_CLEAN_SYMBOL_MANAGEMENT_EDIT: "/siteadmin/symbolconfig/cleansymbol/edit",
    CENTRALIZED_SETTINGS_MT_SYMBOL_MANAGEMENT_VIEW: "/siteadmin/symbolconfig/symbolmap/view",
    CENTRALIZED_SETTINGS_MT_SYMBOL_MANAGEMENT_EDIT: "/siteadmin/symbolconfig/symbolmap/edit",
    CENTRALIZED_SETTINGS_SYMBOL_ASSET_TYPE_MANAGEMENT_VIEW: "/siteadmin/symbolconfig/assettype/view",
    CENTRALIZED_SETTINGS_SYMBOL_ASSET_TYPE_MANAGEMENT_EDIT: "/siteadmin/symbolconfig/assettype/edit",
    CENTRALIZED_SETTINGS_SYMBOL_MANAGEMENT_VIEW: "/siteadmin/symbolconfig/symbolinfo/view",
    CENTRALIZED_SETTINGS_SYMBOL_MANAGEMENT_EDIT: "/siteadmin/symbolconfig/symbolinfo/edit",
    CENTRALIZED_SETTINGS_COMMISSION_SETTINGS_VIEW: "/siteadmin/brandserver/commissionconfig/view",
    CENTRALIZED_SETTINGS_COMMISSION_SETTINGS_EDIT: "/siteadmin/brandserver/commissionconfig/edit",
    CENTRALIZED_SETTINGS_FEATURE_SETTINGS_VIEW: "/siteadmin/brandserver/featuresetting/view",
    CENTRALIZED_SETTINGS_FEATURE_SETTINGS_EDIT: "/siteadmin/brandserver/featuresetting/edit",
    CENTRALIZED_SETTINGS_USER_MANAGEMENT_VIEW: "/siteadmin/user/view",
    CENTRALIZED_SETTINGS_USER_MANAGEMENT_EDIT: "/siteadmin/user/edit",
    CENTRALIZED_SETTINGS_ROLE_AND_PERMISSION_VIEW: "/siteadmin/rolepermission/view",
    CENTRALIZED_SETTINGS_ROLE_AND_PERMISSION_EDIT: "/siteadmin/rolepermission/edit",
    CENTRALIZED_SETTINGS_MENU_MANAGEMENT_VIEW: "/siteadmin/menus/view",
    CENTRALIZED_SETTINGS_MENU_MANAGEMENT_EDIT: "/siteadmin/menus/edit",
    CENTRALIZED_SETTINGS_LINKED_ACCOUNT_REPORT_SETTINGS_VIEW: "/siteadmin/reportinganalysis/linkedaccountreportsettings/view",
    CENTRALIZED_SETTINGS_LINKED_ACCOUNT_REPORT_SETTINGS_EDIT: "/siteadmin/reportinganalysis/linkedaccountreportsettings/edit",
    CENTRALIZED_SETTINGS_JOB_SCHEDULER_VIEW: "/siteadmin/schedulers/view",
    CENTRALIZED_SETTINGS_JOB_SCHEDULER_EDIT: "/siteadmin/schedulers/edit",
    CENTRALIZED_SETTINGS_TOXIC_CLIENT_SETTINGS_VIEW: "/siteadmin/reportinganalysis/toxicclientsettings/view",
    CENTRALIZED_SETTINGS_TOXIC_CLIENT_SETTINGS_EDIT: "/siteadmin/reportinganalysis/toxicclientsettings/edit",
    CENTRALIZED_SETTINGS_CID_SETTINGS_VIEW: "/siteadmin/adminratools/cidsettings/view",
    CENTRALIZED_SETTINGS_CID_SETTINGS_EDIT: "/siteadmin/adminratools/cidsettings/edit",
    CENTRALIZED_SETTINGS_FUNDING_FEE_SETTINGS_VIEW: "/siteadmin/adminratools/fundingfeesettings/view",
    CENTRALIZED_SETTINGS_FUNDING_FEE_SETTINGS_EDIT: "/siteadmin/adminratools/fundingfeesettings/edit",
    CENTRALIZED_SETTINGS_GROUP_TRANSFER_TOOL_SETTINGS_VIEW: "/siteadmin/adminratools/grouptransfertoolsettings/view",
    CENTRALIZED_SETTINGS_GROUP_TRANSFER_TOOL_SETTINGS_EDIT: "/siteadmin/adminratools/grouptransfertoolsettings/edit",
    CENTRALIZED_SETTINGS_HUB_FAILOVER_CONFIGURATION_VIEW: "/siteadmin/adminratools/hubfailoverconfiguration/view",
    CENTRALIZED_SETTINGS_HUB_FAILOVER_CONFIGURATION_EDIT: "/siteadmin/adminratools/hubfailoverconfiguration/edit",
    CENTRALIZED_SETTINGS_ADMIN_FEE_SETTINGS_VIEW: "/siteadmin/adminratools/adminfeesettings/view",
    CENTRALIZED_SETTINGS_ADMIN_FEE_SETTINGS_EDIT: "/siteadmin/adminratools/adminfeesettings/edit",
};
