import { EditOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Tag, Typography, notification } from "antd";
import { useMemo, useState } from "react";
import { colorMap } from "../../StatusMonitor/statusUtils";
import Modal from "antd/lib/modal/Modal";
import { useRCBalanceAdjustmentMutate } from "../../../../hooks/useRCBalanceAdjustment";
import { plainAxiosInstance } from "../../../../services/axiosSetup";
import moment from "moment";
import { APIs } from "../../../../services/apis";
import MessageCard from "../../components/MessageCard";
import { getFileNameFromResponseHeader } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { filterAllColsClientTable } from "@/utils/array";

const { Text } = Typography;

interface BalanceAdjustmentTableProps {
    data: any;
    commented?: boolean;
    solved?: boolean;
    mutationCallback?: () => void;
}

const BalanceAdjustmentTable = ({ data, commented = false, solved = false, mutationCallback = () => {} }: BalanceAdjustmentTableProps) => {
    const { mutateAddComment, mutateSolveIssue } = useRCBalanceAdjustmentMutate();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchText, setSearchText] = useState<string>("");
    const [editSingleCommentBatchId, setEditSingleCommentBatchId] = useState<null | number>(null);
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const [commentText, setCommentText] = useState<string>("");
    const [openVerifyModal, setOpenVerifyModal] = useState(false);

    const dataWithKey = useMemo(
        () =>
            data.length >= 1
                ? data
                      .map((item: any) => ({ ...item, key: item.batchId }))
                      .sort((a: any, b: any) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime())
                : [],
        [data]
    );

    const result: any[] = useMemo(
        () => (dataWithKey.length > 0 ? (isEmptyOrNull(searchText) ? dataWithKey : filterAllColsClientTable(dataWithKey, searchText)) : []),
        [dataWithKey, searchText]
    );

    const detailColumns = useMemo(
        () =>
            [
                {
                    title: "Datetime",
                    dataIndex: "dateTimeStr",
                    // ascend
                    defaultSortOrder: "ascend",
                    // sorter: (a: any, b: any) => a.dateTimeStr.localeCompare(b.dateTimeStr),
                },
                {
                    title: "Server",
                    dataIndex: "serverId",
                    sorter: (a: any, b: any) =>
                        // localecompare
                        a.serverId.localeCompare(b.serverId),
                },
                {
                    title: "Message",
                    dataIndex: "msg",
                    sorter: (a: any, b: any) =>
                        // localecompare
                        a.msg.localeCompare(b.msg),
                },
                {
                    title: "Ip",
                    dataIndex: "ip",
                    sorter: (a: any, b: any) => a.ip.localeCompare(b.ip),
                },
                {
                    title: "Comment User",
                    dataIndex: "commentUser",
                    sorter: (a: any, b: any) => a.commentUser.localeCompare(b.commentUser),
                    render: (text: string, data: any) => {
                        return <p style={{ margin: 0, padding: 0, wordBreak: "break-word" }}>{text}</p>;
                    },
                },
                {
                    title: "Comment Time",
                    dataIndex: "commentTimeStr",
                    sorter: (a: any, b: any) => a.commentTimeStr.localeCompare(b.commentTimeStr),
                },
                {
                    title: "Comment",
                    dataIndex: "comment",
                    render: (text: string | null, data: any) => {
                        if (solved) return <p style={{ margin: 0, padding: 0 }}>{text}</p>;
                        if (text === null)
                            return (
                                <Button
                                    type="ghost"
                                    size="small"
                                    onClick={() => {
                                        setEditSingleCommentBatchId(data.batchId);
                                        setCommentText(text ?? "");
                                        setOpenCommentModal(true);
                                    }}
                                    icon={<EditOutlined />}
                                />
                            );
                        return (
                            <Space wrap size={1}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    onClick={() => {
                                        setEditSingleCommentBatchId(data.batchId);
                                        setCommentText(text ?? "");
                                        setOpenCommentModal(true);
                                    }}
                                    icon={<EditOutlined />}
                                />
                                <p style={{ margin: 0, padding: 0 }}>{text}</p>
                            </Space>
                        );
                    },
                },
                {
                    title: "Solved User",
                    dataIndex: "solvedUser",
                    sorter: (a: any, b: any) => a.solvedUser.localeCompare(b.solvedUser),
                    render: (text: string, data: any) => {
                        return <p style={{ margin: 0, padding: 0, wordBreak: "break-word" }}>{text}</p>;
                    },
                },
                {
                    title: "Solved Time",
                    dataIndex: "solvedTimeStr",
                    sorter: (a: any, b: any) => a.solvedTimeStr.localeCompare(b.solvedTimeStr),
                },
                {
                    title: "Order Lock",
                    dataIndex: "orderLock",
                    sorter: (a: any, b: any) => a.orderLock.localeCompare(b.orderLock),
                    render: (text: string, data: any) => {
                        if (text === "") return "";
                        return <Tag color={text.toLowerCase() === "fail" ? colorMap.error : colorMap.success}>{text}</Tag>;
                    },
                },
            ].filter(col => {
                if (col.dataIndex === "commentUser" && !commented) return false;
                if (col.dataIndex === "commentTimeStr" && !commented) return false;
                if (col.dataIndex === "solvedUser" && !solved) return false;
                if (col.dataIndex === "solvedTimeStr" && !solved) return false;
                return true;
            }),
        [commented, solved]
    );

    const simplifiedColumns = [
        {
            title: "Server",
            dataIndex: "serverId",
            sorter: (a: any, b: any) => a.serverId.localeCompare(b.serverId),
        },
        {
            title: "Message",
            dataIndex: "msg",
            sorter: (a: any, b: any) => a.msg.localeCompare(b.msg),
        },
    ];

    const simplifiedColumnsWithComment = [
        {
            title: "Server",
            dataIndex: "serverId",
            sorter: (a: any, b: any) =>
                // localecompare
                a.serverId.localeCompare(b.serverId),
        },
        {
            title: "Message",
            dataIndex: "msg",
            sorter: (a: any, b: any) =>
                // localecompare
                a.msg.localeCompare(b.msg),
        },
        {
            title: "Comment",
            dataIndex: "comment",
            render: (text: string | null, data: any) => {
                if (text === null) return "";
                return (
                    <p style={{ margin: 0, padding: 0 }}>
                        {text}
                        <br />
                        <small>by {data.commentUser}</small>
                    </p>
                );
            },
        },
    ];

    const handleDownload = async () => {
        // GET_BALANCE_ADJUSTMENT_DOWNLOAD
        // date=${YYYY-MM-DD}&solvedStaus=0
        const date = moment().format("YYYY-MM-DD");
        plainAxiosInstance
            .get(`${APIs.RC_ISSUES_LOG.GET_BALANCE_ADJUSTMENT_DOWNLOAD}?date=${date}&solvedStaus=0`, {
                headers: {
                    Accept: "application/octet-stream, */*",
                },
                responseType: "blob",
            })
            .then(response => {
                // Check if the response content type is 'application/octet-stream'
                const contentType = response.headers["content-type"];
                const fileName = getFileNameFromResponseHeader(response, `balanceAdjustment_${date}.csv`);
                if (
                    contentType === "application/octet-stream" ||
                    contentType === "text/csv" ||
                    contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ) {
                    // Handle the file download response
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();

                    window.URL.revokeObjectURL(url);
                    notification.success({
                        message: "Downloaded",
                        description: `Balance Adjustment report on ${date} downloaded successfully`,
                    });
                } else {
                    notification.error({
                        message: "Error",
                        description: `Received non-file response. Error: ${response}`,
                    });
                }
            })
            .catch(err => {
                notification.error({
                    message: "Error",
                    description: `Download error: ${err}`,
                });
            });
    };

    const handleAddComment = async ({ id, comment }: { id: number[]; comment: string }) => {
        try {
            const resp = await mutateAddComment({ id, comment });
            if (resp.data > 0) {
                notification.success({
                    message: "Comment Added",
                    description: `Comment added successfully`,
                });
                mutationCallback();
                setOpenCommentModal(false);
                setSelectedRowKeys([]);
                setCommentText("");
            } else {
                notification.error({
                    message: "Error",
                    description: `Error adding comment`,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: `Error adding comment`,
            });
        }
    };

    const handleVerify = async ({ id, comment = " " }: { id: number[]; comment?: string }) => {
        try {
            const resp = await mutateSolveIssue({ id, comment });

            if (resp.data > 0) {
                comment === " "
                    ? notification.success({
                          message: "Success",
                          description: `Issue verified successfully`,
                      })
                    : notification.success({
                          message: "Success",
                          description: `Issue verified with your comments`,
                      });
                mutationCallback();
                setOpenCommentModal(false);
                setSelectedRowKeys([]);
                setCommentText("");
            } else {
                notification.error({
                    message: "Error",
                    description: comment === " " ? `Error while verifying` : `Error adding comment`,
                });
            }
        } catch (error) {
            notification.error({
                message: "Error",
                description: comment === " " ? "Error while verifying" : `Error adding comment`,
            });
        }
    };

    return (
        <Space direction="vertical" style={{ width: "100%", padding: "1rem" }}>
            {!solved && (
                <Space>
                    <Button type="primary" onClick={() => setOpenCommentModal(true)} disabled={selectedRowKeys?.length < 1}>
                        Add Comment
                    </Button>
                    {!commented && !solved && (
                        <Button type="primary" onClick={() => handleDownload()}>
                            Download
                        </Button>
                    )}
                    {commented && (
                        <Button type="primary" disabled={selectedRowKeys?.length < 1} onClick={() => setOpenVerifyModal(true)}>
                            Verify
                        </Button>
                    )}
                </Space>
            )}
            <Input value={searchText} onChange={e => setSearchText(e.target.value)} placeholder="Search" allowClear />
            <Table
                //@ts-ignore
                columns={detailColumns}
                dataSource={result}
                size="small"
                pagination={{
                    defaultPageSize: 50,
                    pageSizeOptions: ["10", "20", "50", "100", "500", "1000"],
                }}
                rowSelection={
                    !solved
                        ? {
                              selectedRowKeys,
                              onChange: (selectedRowKeys: any) => setSelectedRowKeys(selectedRowKeys),
                          }
                        : undefined
                }
            />

            <Modal
                title="Add or Edit Comment"
                open={openCommentModal && (selectedRowKeys?.length >= 1 || editSingleCommentBatchId !== null)}
                onOk={() => {
                    if (!commented) {
                        if (editSingleCommentBatchId !== null) {
                            handleAddComment({ id: [editSingleCommentBatchId], comment: commentText });
                        } else {
                            handleAddComment({ id: selectedRowKeys, comment: commentText });
                        }
                    } else {
                        if (editSingleCommentBatchId !== null) {
                            handleVerify({ id: [editSingleCommentBatchId], comment: commentText });
                        } else {
                            handleVerify({ id: selectedRowKeys, comment: commentText });
                        }
                    }
                }}
                okText="Add Comment"
                okButtonProps={{ disabled: commentText.trim() === "" }}
                onCancel={() => {
                    if (editSingleCommentBatchId !== null) {
                        setEditSingleCommentBatchId(null);
                    }
                    setOpenCommentModal(false);
                    setCommentText("");
                }}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    {editSingleCommentBatchId !== null ? (
                        <div>
                            <Text>Editing comment on</Text>
                            <Table
                                dataSource={dataWithKey.filter((item: any) => item.batchId === editSingleCommentBatchId)}
                                pagination={false}
                                columns={simplifiedColumns}
                                size="small"
                            />
                        </div>
                    ) : (
                        <div>
                            <Text>Editing comment on</Text>
                            <Table
                                // @ts-ignore
                                dataSource={dataWithKey.filter((item: any) => selectedRowKeys?.includes(item?.batchId))}
                                pagination={false}
                                columns={simplifiedColumns}
                                size="small"
                            />
                        </div>
                    )}

                    <Text>Comment *</Text>
                    <Space size={1} direction="vertical" style={{ width: "100%" }}>
                        <Input
                            value={commentText}
                            onChange={e => {
                                setCommentText(e.target.value);
                            }}
                        />
                        {commentText.trim() === "" && (
                            <Text type="danger">
                                <small>Comment cannot be empty</small>
                            </Text>
                        )}
                    </Space>
                    <MessageCard type="info">Upon adding comment, will bring the issue to the next stage.</MessageCard>
                </Space>
            </Modal>

            <Modal
                title="Verify Issue"
                open={openVerifyModal}
                okText="Verify"
                onOk={() => {
                    handleVerify({ id: selectedRowKeys });
                }}
                onCancel={() => {
                    setOpenVerifyModal(false);
                }}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>Are you sure you want to verify issues below?</Text>
                    <Table
                        // @ts-ignore
                        dataSource={dataWithKey.filter((item: any) => selectedRowKeys?.includes(item?.batchId))}
                        pagination={false}
                        columns={simplifiedColumnsWithComment}
                        size="small"
                    />
                    <MessageCard type="info">Upon verifying, the issue will be marked as solved.</MessageCard>
                </Space>
            </Modal>
        </Space>
    );
};

export default BalanceAdjustmentTable;
