import CardBox from "@/components/Common/CardBox";
import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType } from "@/constants";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "@/constants/type";
import { APIs } from "@/services/apis";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { SortList, ToObjectWithKey } from "@/utils/array";
import { DTColProps } from "@/utils/Common";
import { DefaultIfEmpty } from "@/utils/object";
import { isEmptyOrNull } from "@/utils/string";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

interface TransactionOrderCheckerProps {}

interface TransactionOrderCheckerDataProps {
    server: string;
    login?: string;
    categories: { [key: string]: TransactionOrderCheckerCategoriesItemProps | TransactionOrderCheckerCategoriesItemProps[] };
}

interface TransactionOrderCheckerCategoriesItemProps {
    subType: { [key: number]: boolean };
    login?: string;
    status: boolean;
}

const SubType: { [key: string]: string } = {
    "0": "PE",
    "1": "OZ Normal",
    "2": "OZ Stock",
    "3": "PXM",
};

const TransactionOrderChecker = (props: TransactionOrderCheckerProps) => {
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<TransactionOrderCheckerDataProps[]>([]);
    const [filterParams, setFilterParams] = useState<any>({});
    const [servers, setServers] = useState<KeyValuePair[]>([]);

    const markupData = useMemo(() => {
        if (data.length > 0) {
            let initData = data.reduce((acc: any, x: TransactionOrderCheckerDataProps, xIdx: number) => {
                    acc[x.server] = Object.keys(x.categories).reduce(
                        (zObj: { subType: any; login: any }, z: string) => {
                            if (Array.isArray(x.categories[z])) {
                                (x.categories[z] as TransactionOrderCheckerCategoriesItemProps[]).forEach(
                                    (k: TransactionOrderCheckerCategoriesItemProps) => {
                                        Object.keys(DefaultIfEmpty(k, "subType", {})).forEach((y: string) => {
                                            zObj.subType[y] = false;
                                        });

                                        if (k.hasOwnProperty("login") && !isEmptyOrNull(k.login)) {
                                            zObj.login[k.login as string] = Object.keys(DefaultIfEmpty(k, "subType", {}));
                                        }
                                    }
                                );
                            } else {
                                Object.keys(DefaultIfEmpty(x.categories[z], "subType", {})).forEach((y: string) => {
                                    zObj.subType[y] = false;
                                });
                            }
                            return zObj;
                        },
                        { subType: {}, login: {} }
                    );

                    return acc;
                }, {}),
                dataWithServer = ToObjectWithKey(data, "server"),
                dataWithKey = Object.keys(initData).reduce((keyObj: any[], server: string) => {
                    (Object.keys(initData[server].login).length > 0 ? Object.keys(initData[server].login) : [" "]).forEach((mLogin: string) => {
                        Object.keys(initData[server].subType).forEach((mType: string) => {
                            keyObj.push({
                                key: `${server}-${mLogin}-${mType}`,
                                server: server,
                                login: mLogin,
                                type: mType,
                                ...Object.keys(dataWithServer[server].categories).reduce((zObj: any, z: string) => {
                                    zObj[z] = Array.isArray(dataWithServer[server].categories[z])
                                        ? (
                                              DefaultIfEmpty(ToObjectWithKey(dataWithServer[server].categories[z], "login"), mLogin, {
                                                  subType: {},
                                              }) as TransactionOrderCheckerCategoriesItemProps
                                          ).subType[parseInt(mType)]
                                        : (dataWithServer[server].categories[z] as TransactionOrderCheckerCategoriesItemProps).subType[
                                              parseInt(mType)
                                          ];

                                    return zObj;
                                }, {}),
                            });
                        });
                    });
                    return keyObj;
                }, []);

            return SortList(dataWithKey, "server");
        }
        return [];
    }, [data]);

    const columns: any[] = useMemo(() => {
        let basicColumns = [
            {
                title: "Search Date Time",
                dataIndex: "dateTime",
                key: "dateTime",
                options: {
                    visible: false,
                    filter: {
                        type: ComponentType.date,
                        value: "",
                        dateFormat: "YYYY-MM-DD HH:mm",
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        inputProps: {
                            showTime: { format: "HH:mm" },
                            disabledDate: (current: any) => {
                                return current && current > moment().endOf("day");
                            },
                        },
                    },
                },
            },
            {
                title: "Server",
                dataIndex: "server",
                key: "server",
                options: {
                    filter: {
                        type: ComponentType.dropdown,
                        value: servers,
                        rules: [{ required: true, message: REQUIRED_FIELD }],
                        inputProps: {
                            mode: "multiple",
                        },
                    },
                },
            },
            {
                title: "Manager Login",
                dataIndex: "login",
                key: "login",
            },
            {
                title: "Manager Type",
                dataIndex: "type",
                key: "type",
                render: (type: string) => DefaultIfEmpty(SubType, type, type),
            },
        ];
        return basicColumns.concat(
            data.length > 0
                ? Object.keys(data[0].categories).map((x: string) =>
                      DTColProps.Small(
                          {
                              title: x,
                              dataIndex: x,
                              key: x,
                              render: (text: boolean) =>
                                  text ? (
                                      <CheckCircleOutlined className="text-color-success" style={{ fontSize: "1.25rem" }} />
                                  ) : (
                                      <CloseCircleOutlined className="text-color-error" style={{ fontSize: "1.25rem" }} />
                                  ),
                          },
                          ["text-center"]
                      )
                  )
                : []
        );
    }, [servers, data]);

    const options: FlexiDataTableOptionsProps = {
        serverFiltering: true,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                setFilterParams(
                    Object.keys(FormData).reduce((acc: any, key: string) => {
                        if (key === "server") {
                            acc["serverUno"] = FormData[key].join(",");
                        } else if (key === "dateTime") {
                            acc[key] = moment(FormData[key]).format("YYYY-MM-DD HH:mm");
                        } else {
                            acc[key] = FormData[key];
                        }

                        return acc;
                    }, {})
                );
                setRunRefetchDataList(true);
                break;
            default:
                break;
        }
    };

    const getLogs = useCallback(() => {
        setIsLoading(true);
        plainAxiosInstance
            .get(`${APIs.RC_TRANSACTION_ORDER_CHECKER.GET_LOGS}?${new URLSearchParams(filterParams).toString()}`)
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .finally(() => setIsLoading(false));
    }, [filterParams]);

    const getConfig = () => {
        plainAxiosInstance
            .get(APIs.RC_TRANSACTION_ORDER_CHECKER.GET_SERVERS)
            .then((res: any) => {
                res.data &&
                    res.data.length > 0 &&
                    setServers(
                        SortList(
                            res.data.map((x: any) => ({ text: x.label, value: x.value })),
                            "text"
                        )
                    );
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (runRefetchDataList) {
            getLogs();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
    }, []);

    return (
        <div className="transaction-order-checker-container">
            <CardBox title={"Transaction Order Checker"}>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="key"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={markupData}
                    callback={componentCallback}
                    loading={isLoading}
                    tableProps={{
                        bordered: true,
                    }}
                />
            </CardBox>
        </div>
    );
};

export default TransactionOrderChecker;
