import FlexiDataTable from "@/components/FlexiDataTable";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { APIs } from "@/services/apiConfig";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { ExclamationCircleOutlined, HistoryOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import HubToHubActivityLogModal, { HubToHubActivityLogModalCallbackKey } from "./hthActivityLogModal";

interface HubToHubListingPageProps {}

export interface HTHListItemProps {}

const HubToHubListingPage = (props: HubToHubListingPageProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<HTHListItemProps[]>([{}]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<HTHListItemProps | undefined>(undefined);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.RISK_TOOLS_HUB_TO_HUB_EDIT);

    const columns = [
        {
            title: "Server",
            dataIndex: "",
            key: "",
        },
        {
            title: "Login",
            dataIndex: "login",
            key: "login",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },
        {
            title: "Bridge",
            dataIndex: "",
            key: "",
        },
        {
            title: "Group",
            dataIndex: "",
            key: "",
        },
        {
            title: "Symbol",
            dataIndex: "",
            key: "",
        },
        {
            title: "Inspector",
            dataIndex: "",
            key: "",
        },
        {
            title: "Date",
            dataIndex: "",
            key: "",
        },
        {
            title: "Comment",
            dataIndex: "",
            key: "",
        },
        {
            title: "Note",
            dataIndex: "",
            key: "",
        },
        {
            title: "Label",
            dataIndex: "",
            key: "",
        },
        {
            title: "Status",
            dataIndex: "",
            key: "",
        },
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        export: {
            text: "Export to Excel",
        },
        ...(enableUpdate && {
            rowExtra: [{ text: "Activity Log", icon: <HistoryOutlined />, value: "historicallist" }],
            upload: {
                name: "file",
                multiple: false,
                accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                showUploadList: false,
                onChange: (info: any) => {
                    if (info.file.status === "error") {
                        ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                },
                customRequest: ({ file, onSuccess }: any) =>
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0),
                beforeUpload: (file: any) => handleSubmit(file),
            },
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "historicallist") {
                    setModalDetails(FormData.data);
                    setIsModalVisible(true);
                }
                break;
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                exportToExcel(FormData);
                break;
            default:
                break;
        }
    };

    const exportToExcel = (filterParam: any) => {
        try {
            plainAxiosInstance
                .get(APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING_EXPORT_TO_EXCEL, {
                    headers: {
                        Accept: "application/octet-stream, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `HubtoHubList.xlsx`);
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((error: any) => ErrorMessageHandler(`Error occured during download: "${error.message}"`, SUCCESS_FAILED.OTHERS_FAILED));
        } catch (e: any) {
            ErrorMessageHandler(`Error occured during download: "${e.message}"`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const handleSubmit = (file: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(file.name), [""]),
                isLt5M = file.size / 1024 / 1024 < 5;

            if (fileExtension[0] !== ".xlsx") {
                ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler("Please check file size less than 5 MB.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to import?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("uploadFile", file);

                    plainAxiosInstance
                        .post(APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING_UPLOAD, formData)
                        .then(res => {
                            if (res.status === 200 && res.data === 0) {
                                ErrorMessageHandler(`File upload successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                            } else {
                                ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed: (${error.response.data.message}).`, SUCCESS_FAILED.OTHERS_FAILED);
                        })
                        .finally(() => getListing());
                },
                onCancel() {},
            });
        } catch (error) {
            ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const getListing = () => {
        setIsLoading(true);
        plainAxiosInstance
            .post(APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING, {})
            .then((res: any) => {
                setData(res.data && res.data.length > 0 ? res.data : []);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("hub to hub", SUCCESS_FAILED.FAILED_LOAD_DATA, err)))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setIsLoading(false);
        //getListing();
    }, []);

    return (
        <>
            <div className="hth-listing-page-container">
                <FlexiDataTable
                    bordered
                    rowKeyProperty="id"
                    title=""
                    columns={columns}
                    options={options}
                    dataSource={data}
                    callback={componentCallback}
                    loading={isLoading}
                />
            </div>
            <HubToHubActivityLogModal
                isModalVisible={isModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case HubToHubActivityLogModalCallbackKey.Close:
                            setIsModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                data={modalDetails}
            />
        </>
    );
};

export default HubToHubListingPage;
