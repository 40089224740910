import CardBox from "@/components/Common/CardBox";
import { Segmented } from "antd";
import { useState } from "react";
import HubToHubListingPage from "./components/hthlist";

interface HubToHubToolProps {}

const HubToHubTool = (props: HubToHubToolProps) => {
    const [currentTab, setCurrentTab] = useState<string>("HTH List");

    return (
        <div className="risk-tool-hub-to-hub-container">
            <CardBox title={"Hub to Hub Tool"}>
                <div className="main-container">
                    <div className="top-container">
                        <div className="left">
                            <Segmented
                                value={currentTab}
                                options={["HTH List", "Reservation Settings", "Conflict List"]}
                                onChange={(activeKey: any) => setCurrentTab(activeKey)}
                            />
                        </div>
                    </div>
                    {currentTab === "HTH List" && <HubToHubListingPage />}
                    {currentTab === "Reservation Settings" && <>not implement</>}
                    {currentTab === "Conflict List" && <>not implement</>}
                </div>
            </CardBox>
        </div>
    );
};

export default HubToHubTool;
