import { LineChartOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMemo } from "react";
import FlexiDataTable from "../../../../components/FlexiDataTable";
import { FlexiDataTableOptionsProps, PriceMonitorSpreadAlarmDetailsProps } from "../../../../constants/type";
import { useGetResolvedAlarmListQuery } from "../../../../store/apis/pricemonitor/spreadalarm";
import { currencyRender, DTColProps } from "../../../../utils/Common";

export interface ResolveSpreadAlarmListProps {
    showSpreadLineCallback: (data: PriceMonitorSpreadAlarmDetailsProps) => void;
}

const ResolveSpreadAlarmList = (props: ResolveSpreadAlarmListProps) => {
    const { data, isError, isFetching } = useGetResolvedAlarmListQuery({ url: "/1440/1" });

    const markupData = useMemo(() => {
        return isFetching || isError
            ? []
            : data?.data.map((x: any) => ({
                ...x,
                rowKey: `${x.server}-${x.symbol}-${x.dateTimeStr}`,
                showSpread: currencyRender(x.spread.toFixed(0)),
                showSpreadThreshold: currencyRender(x.spreadThreshold.toFixed(0)),
                lowThreshold: currencyRender(x.lowThreshold.toFixed(0)),
            })) || [];
    }, [isError, isFetching, data]);

    const tableCols: any = [
        DTColProps.XXSmall(
            {
                title: "Line",
                dataIndex: "Action",
                key: "Action",
                render: (text: string, record: PriceMonitorSpreadAlarmDetailsProps) => {
                    return (
                        <Button
                            type="primary"
                            icon={<LineChartOutlined />}
                            onClick={() => props.showSpreadLineCallback && props.showSpreadLineCallback(record)}
                        />
                    );
                },
            },
            ["text-center"]
        ),
        DTColProps.DateTime({
            title: "Date Time",
            dataIndex: "dateTimeStr",
            key: "dateTimeStr",
        }),
        {
            title: "Server",
            dataIndex: "server",
            key: "server",
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
        },
        DTColProps.Small(
            {
                title: "Spread",
                dataIndex: "showSpread",
                key: "showSpread",
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Spread Threshold",
                dataIndex: "showSpreadThreshold",
                key: "showSpreadThreshold",
            },
            ["text-right"]
        ),
        DTColProps.Small(
            {
                title: "Low Threshold",
                dataIndex: "lowThreshold",
                key: "lowThreshold",
            },
            ["text-right"]
        ),
        {
            title: "Solved User",
            dataIndex: "solvedUser",
            key: "solvedUser",
        },
        DTColProps.DateTime({
            title: "Solved Time",
            dataIndex: "solvedTimeStr",
            key: "symbol",
        }),
    ];

    const tableOpts: FlexiDataTableOptionsProps = {
        enableFilter: false,
    };

    return (
        <div className="unresolved-spread-alarm-container">
            <FlexiDataTable
                bordered
                rowKeyProperty="rowKey"
                title={false}
                columns={tableCols}
                options={tableOpts}
                dataSource={markupData}
                loading={isFetching}
            />
        </div>
    );
};

export default ResolveSpreadAlarmList;
