import { useCallback, useEffect, useMemo, useState } from "react";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { IRCPriceAlarmSettingDetails, useRCPriceAlarmSettingServers } from "@/hooks/useRCPriceAlarmSettings";
import { CALLBACK_KEY, ComponentType, InnerPageActionMode, SUCCESS_FAILED } from "@/constants";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Typography, Upload } from "antd";
import { DownloadOutlined, ExclamationCircleOutlined, RedoOutlined, SaveOutlined, SearchOutlined, UndoOutlined, UploadOutlined } from "@ant-design/icons";
import FlexiDataTable from "@/components/FlexiDataTable";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler, getFileNameFromResponseHeader } from "@/utils/Common";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, IValidateError, PASDropdownInputFieldProps, PASNormalInputFieldProps, PASTimeRangeInputFieldProps } from "@/constants/type";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { defaultIfEmptyOrNull } from "@/utils/string";
import { REQUIRED_FIELD } from "@/constants/errorMessage";
import { differenceWith, isEqual } from "lodash";

interface PriceAlarmSettingDetailsExtra extends IRCPriceAlarmSettingDetails {
    isCreateEdit?: InnerPageActionMode.CREATE_NEW | InnerPageActionMode.EDIT | undefined;
    newKey?: string;
    isDeleted?: boolean;
};

const initFormValue = {
    symbol: "",
    cleanSymbol: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
    noPriceTime: "",
    openNoPrice: "",
    digit: "",
    showStatus: null,
    alertStatus: null,
    forceSession: null,
};

const PriceAlarmSettings2 = () => {
    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_MID_PRICE_SETTINGS_EDIT);

    const { rcPriceAlarmSettingServers, isLoading: isLoadingServers } = useRCPriceAlarmSettingServers();
    const [oriData, setOriData] = useState<IRCPriceAlarmSettingDetails[]>([]);
    const [data, setData] = useState<PriceAlarmSettingDetailsExtra[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [currEdit, setCurrEdit] = useState<InnerPageActionMode.CREATE_NEW | PriceAlarmSettingDetailsExtra | null>(null);
    const [priceAlarmSettingForm] = Form.useForm();
    const [ableToResetSave, setAbleToResetSave] = useState<boolean>(false);
    const [isResetting, setIsResetting] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [serverType, setServerType] = useState<string>("all");
    const [server, setServer] = useState<string | null>(null);
    const [symbolsList, setSymbolsList] = useState<string[]>([]);
    const [validateError, setValidateError] = useState<IValidateError[]>([]);

    const columns = [
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            fixed: "left",
            sorter: (a: any, b: any) => a.symbol.localeCompare(b.symbol),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Clean Symbol",
            dataIndex: "cleanSymbol",
            key: "cleanSymbol",
            sorter: (a: any, b: any) => a.cleanSymbol.localeCompare(b.cleanSymbol),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Monday",
            dataIndex: "monday",
            key: "monday",
            width: "10vw",
            sorter: (a: any, b: any) => a.monday.localeCompare(b.monday),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Tuesday",
            dataIndex: "tuesday",
            key: "tuesday",
            width: "10vw",
            sorter: (a: any, b: any) => a.tuesday.localeCompare(b.tuesday),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Wednesday",
            dataIndex: "wednesday",
            key: "wednesday",
            width: "10vw",
            sorter: (a: any, b: any) => a.wednesday.localeCompare(b.wednesday),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Thursday",
            dataIndex: "thursday",
            key: "thursday",
            width: "10vw",
            sorter: (a: any, b: any) => a.thursday.localeCompare(b.thursday),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Friday",
            dataIndex: "friday",
            key: "friday",
            width: "10vw",
            sorter: (a: any, b: any) => a.friday.localeCompare(b.friday),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Saturday",
            dataIndex: "saturday",
            key: "saturday",
            width: "10vw",
            sorter: (a: any, b: any) => a.saturday.localeCompare(b.saturday),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "Sunday",
            dataIndex: "sunday",
            key: "sunday",
            width: "10vw",
            sorter: (a: any, b: any) => a.sunday.localeCompare(b.sunday),
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        }),
        DTColProps.Small({
            title: "No Price Time",
            dataIndex: "noPriceTime",
            key: "noPriceTime",
            sorter: (a: any, b: any) => a.noPriceTime - b.noPriceTime,
        }),
        DTColProps.Small({
            title: "Open No Price",
            dataIndex: "openNoPrice",
            key: "openNoPrice",
            sorter: (a: any, b: any) => a.openNoPrice - b.openNoPrice,
        }),
        DTColProps.Small({
            title: "Symbol Type",
            dataIndex: "typeName",
            key: "typeName",
            sorter: (a: any, b: any) => a.typeName.localeCompare(b.typeName),
        }),
        DTColProps.XSmall({
            title: "Digit",
            dataIndex: "digit",
            key: "digit",
            sorter: (a: any, b: any) => a.digit - b.digit,
        }),
        DTColProps.XXSmall({
            title: "Show",
            dataIndex: "showStatus",
            key: "showStatus",
            sorter: (a: any, b: any) => a.showStatus - b.showStatus,
            render: (showStatus: number) => showStatus === 1 ? "Hide" : "Show",
        }),
        DTColProps.XSmall({
            title: "Open Sound",
            dataIndex: "alertStatus",
            key: "alertStatus",
            sorter: (a: any, b: any) => a.alertStatus - b.alertStatus,
            render: (alertStatus: number) => alertStatus === 1 ? "Open Sound" : "Mute",
        }),
        DTColProps.XXSmall({
            title: "From RC",
            dataIndex: "forceSession",
            key: "forceSession",
            sorter: (a: any, b: any) => a.forceSession - b.forceSession,
            render: (forceSession: number) => forceSession === 1 ? "True" : "False",
        }),
    ];

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            add: enableUpdate,
            edit: enableUpdate,
            ...(enableUpdate && {
                delete: (record: PriceAlarmSettingDetailsExtra, deleteElement: React.ReactNode) => {
                    if (record.isDeleted) {
                        return undefined;
                    }
                    return deleteElement;
                },
            }),
            ...(enableUpdate && {
                rowExtra: (record: PriceAlarmSettingDetailsExtra) =>
                    record.isDeleted
                        ? [
                            { icon: <RedoOutlined />, text: "Revert", value: "revert" },
                        ]
                        : [],
            }),
            separateActionButton: true,
            defaultCollapseFilterPanel: true,
            extraButtons: () => (
                <div className="extra-header-buttons" key={"ps-mps-extra-buttons"}>
                    <Button
                        icon={<DownloadOutlined />}
                        loading={isDownloading}
                        disabled={isUploading || oriData.length === 0}
                        onClick={() => componentCallback(CALLBACK_KEY.EXPORT_CSV_EXCEL, {})}
                    >
                        Download
                    </Button>
                    <Button
                        icon={<DownloadOutlined />}
                        loading={isDownloading}
                        disabled={isUploading || oriData.length === 0}
                        onClick={() => componentCallback(CALLBACK_KEY.OTHERS, "export-all")}
                    >
                        Download All
                    </Button>
                    <Upload
                        key={`btn-upload-${Math.random()}`}
                        name="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        multiple={false}
                        showUploadList={false}
                        onChange={(info: any) => {
                            if (info.file.status === "error") {
                                ErrorMessageHandler(`${info.file.name} file upload failed.`, SUCCESS_FAILED.OTHERS_FAILED);
                            }
                        }}
                        customRequest={({ file, onSuccess }: any) =>
                            setTimeout(() => {
                                onSuccess("ok");
                            }, 0)
                        }
                        beforeUpload={(file: any) => handleUpload(file)}
                    >
                        <Button htmlType="button" icon={<UploadOutlined style={{ fontSize: "0.875rem" }} />} loading={isUploading} disabled={isDownloading}>
                            Upload
                        </Button>
                    </Upload>
                </div>
            ),
            recordRowClassName: (record: PriceAlarmSettingDetailsExtra) => {
                return record.isDeleted
                    ? "deleted-row"
                    : record.isCreateEdit === InnerPageActionMode.CREATE_NEW
                        ? "new-row"
                        : record.isCreateEdit === InnerPageActionMode.EDIT
                            ? "modified-row"
                            : "";
            },
        };
    }, [enableUpdate, isDownloading, isUploading, oriData]);

    const componentCallback: FlexiDataTableCallbackProps = useCallback((type, FormData) => {
        let copyData = JSON.parse(JSON.stringify(data));
        switch (type) {
            case CALLBACK_KEY.EXPORT_CSV_EXCEL:
                handleDownload({ downloadAll: false });
                break;
            case CALLBACK_KEY.CREATE_NEW:
                priceAlarmSettingForm.setFieldsValue(initFormValue);
                setCurrEdit(InnerPageActionMode.CREATE_NEW);
                break;
            case CALLBACK_KEY.DO_EDIT:
                priceAlarmSettingForm.setFieldsValue(FormData);
                setCurrEdit(FormData);
                break;
            case CALLBACK_KEY.DO_DELETE:
                let updatedData = copyData
                    .map((x: PriceAlarmSettingDetailsExtra) => {
                        let tmp =
                            FormData && x.newKey === FormData.newKey
                                ? FormData.isCreateEdit === InnerPageActionMode.CREATE_NEW
                                    ? null
                                    : {
                                        ...FormData,
                                        isDeleted: true,
                                        isCreateEdit: x.isCreateEdit === InnerPageActionMode.CREATE_NEW
                                            ? InnerPageActionMode.CREATE_NEW
                                            : x.isCreateEdit === InnerPageActionMode.EDIT
                                                ? InnerPageActionMode.EDIT
                                                : undefined,
                                    }
                                : { ...x };
                        return tmp;
                    })
                    .filter((x: PriceAlarmSettingDetailsExtra) => x !== null);
                setData(updatedData);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "export-all") {
                    handleDownload({ downloadAll: true });
                };
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                let currRow = FormData.data;
                if (FormData.key === "revert") {
                    let updatedData = copyData
                        .map((x: PriceAlarmSettingDetailsExtra) => {
                            let tmp =
                                x.newKey === currRow.newKey
                                    ? {
                                        ...currRow,
                                        isCreateEdit: currRow.isCreateEdit === InnerPageActionMode.CREATE_NEW
                                            ? InnerPageActionMode.CREATE_NEW
                                            : currRow.isCreateEdit === InnerPageActionMode.EDIT
                                                ? InnerPageActionMode.EDIT
                                                : undefined,
                                        isDeleted: false,
                                    }
                                    : { ...x };
                            return tmp;
                        })
                    setData(updatedData);
                };
                break;
            default:
                break;
        };
    }, [data, priceAlarmSettingForm]);

    // #region Form Fields
    const NormalInputField = (props: PASNormalInputFieldProps) => {
        return (
            <Col xs={24} sm={12} md={8} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <Form.Item label={props.label} name={props.name} rules={props.rules}>
                    <Input allowClear autoComplete="false" disabled={props.disabled} name="disable-autofill" />
                </Form.Item>
            </Col>
        );
    };

    const InputNumberField = (props: PASNormalInputFieldProps) => {
        return (
            <Col xs={24} sm={12} md={8} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <Form.Item label={props.label} name={props.name} rules={[{ required: true, message: REQUIRED_FIELD }]}>
                    <InputNumber style={{ width: "100%" }} autoComplete="false" disabled={props.disabled} name="disable-autofill" />
                </Form.Item>
            </Col>
        );
    };

    const DropdownInputField: React.FC<PASDropdownInputFieldProps> = (props: PASDropdownInputFieldProps) => {
        const { Text } = Typography;
        return (
            <Col xs={24} sm={12} md={8} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <Form.Item label={<Text>{props.label}</Text>} name={props.name} rules={[{ required: true, message: REQUIRED_FIELD }]}>
                    <Select options={props.options} disabled={props.disabled} />
                </Form.Item>
            </Col>
        );
    };

    const TimeRangeInputField = (props: PASTimeRangeInputFieldProps) => {
        const { Item } = Form;
        const { Text } = Typography;
        // const validateTimeRange = (_: any, value: any) => {
        //     if (!value) {
        //         return Promise.resolve();
        //     }

        //     const timeRangeRegex = /^([01]\d|2[0-3]):[0-5]\d-([01]\d|2[0-3]):[0-5]\d(;([01]\d|2[0-3]):[0-5]\d-([01]\d|2[0-3]):[0-5]\d)?$/;

        //     if (timeRangeRegex.test(value)) {
        //         return Promise.resolve();
        //     }
        //     return Promise.reject("Please enter a valid time range format (e.g., 03:00-15:45 or 03:00-15:45;16:30-21:20)");
        // };
        const validateTimeRange = (_: any, value: string) => {
            if (!value) {
                return Promise.resolve();
            }

            const combinedRanges = validateAndCombineTimeRanges(value);

            if (combinedRanges === null) {
                return Promise.reject("Please enter a valid time range format (e.g., 03:00-15:45 or 03:00-15:45;16:30-21:20)");
            }

            // If the ranges were combined, update the form field value
            if (combinedRanges !== value) {
                // You might need to adjust this part depending on your form library
                // For example, with Ant Design's Form:
                // form.setFieldsValue({ [name]: combinedRanges });
            }

            return Promise.resolve();
        };
        const validateAndCombineTimeRanges = (timeString: string): string | null => {
            // Remove all spaces from the input
            timeString = timeString.replace(/\s/g, "");

            // Split the string into individual ranges
            const ranges = timeString.split(";");

            // Validate each range
            const validRanges = ranges.filter(range => {
                const [start, end] = range.split("-");
                return /^([01]\d|2[0-3]|24):([0-5]\d)$/.test(start) && /^([01]\d|2[0-3]|24):([0-5]\d)$/.test(end);
            });

            if (validRanges.length !== ranges.length) {
                return null; // Invalid format
            }

            // Convert ranges to minutes, sort, and combine
            let timeRanges = validRanges
                .map(range => {
                    const [start, end] = range.split("-").map(timeToMinutes);
                    return { start, end };
                })
                .sort((a, b) => a.start - b.start);

            let combinedRanges = [timeRanges[0]];
            for (let i = 1; i < timeRanges.length; i++) {
                let lastRange = combinedRanges[combinedRanges.length - 1];
                if (timeRanges[i].start <= lastRange.end) {
                    lastRange.end = Math.max(lastRange.end, timeRanges[i].end);
                } else {
                    combinedRanges.push(timeRanges[i]);
                }
            }

            // Convert back to string format
            return combinedRanges.map(range => `${minutesToTime(range.start)}-${minutesToTime(range.end)}`).join(";");
        };

        const timeToMinutes = (time: string): number => {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 60 + minutes;
        };

        const minutesToTime = (minutes: number): string => {
            const hours = Math.floor(minutes / 60);
            const mins = minutes % 60;
            return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
        };

        return (
            <Col xs={24} sm={12} md={8} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <Form.Item label={props.label} name={props.name} rules={[{ required: true, message: "This field is required" }, { validator: validateTimeRange }]}>
                    <Input allowClear />
                </Form.Item>
            </Col>
        );
    };
    // #endregion

    const getPriceAlarmSettings = () => {
        let serverId = server === null ? null : `${server}${serverType === "new" ? "?isNew=1" : serverType === "old" ? "?isNew=0" : ""}`
        setIsLoading(true);
        plainAxiosInstance.get(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_SETTINGS_DETAILS}/${serverId}`, {})
            .then((res: any) => {
                if (res.status === 200) {
                    let newData: any = res.data.map((x: PriceAlarmSettingDetailsExtra) => ({
                        ...x,
                        newKey: `${x.symbol}|${x.cleanSymbol}|${x.serverUno}`,
                    }));
                    setOriData(newData);
                    setData(newData);

                    let symbols: string[] = [];
                    newData.forEach((item: PriceAlarmSettingDetailsExtra) => {
                        symbols.push(item.symbol.trim().toLowerCase());
                    });
                    setSymbolsList(symbols);
                } else {
                    setData([]);
                    setSymbolsList([]);
                };
            })
            .catch((error: any) =>
                ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("price alarm settings", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                    setSymbolsList([]);
                })
            )
            .finally(() => {
                setIsLoading(false);
                setValidateError([]);
            });
    };

    const handleDownload = useCallback(
        async ({ downloadAll = false }: { downloadAll: boolean }) => {
            setIsDownloading(true);
            plainAxiosInstance
                .get(`${APIs.RC_PRICE_SETTINGS.DOWNLOAD_PRICE_ALARM_SETTINGS}` + (downloadAll ? "" : `/${server}`), {
                    headers: {
                        Accept: "application/octet-stream, application/zip, */*",
                    },
                    responseType: "blob",
                })
                .then(response => {
                    // Check if the response content type is 'application/octet-stream'
                    const contentType = response.headers["content-type"];
                    const fileName = getFileNameFromResponseHeader(response, `price_alarm_setting_${server}.xlsx`);
                    if (
                        contentType === "application/octet-stream" ||
                        contentType === "application/zip" ||
                        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ) {
                        // Handle the file download response
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        // Clean up
                        window.URL.revokeObjectURL(url);
                    } else {
                        ErrorMessageHandler(`Received non-file response. Error: ${response}`, SUCCESS_FAILED.OTHERS_FAILED);
                    }
                })
                .catch((err: any) => {
                    ErrorMessageHandler(`Download error: ${err}.`, SUCCESS_FAILED.OTHERS_FAILED);
                    console.log("download error", err);
                })
                .finally(() => setIsDownloading(false));
        },
        [server]
    );

    const handleUpload = (fileToUpload: any) => {
        try {
            let fileExtension: string[] = defaultIfEmptyOrNull(/\.[^\.]+/.exec(fileToUpload.name), [""]),
                isLt5M = fileToUpload.size / 1024 / 1024 < 10;

            if (fileExtension[0] !== ".xlsx") {
                ErrorMessageHandler("Please check file type. Only .xlsx files are allowed.", SUCCESS_FAILED.OTHERS_FAILED);
                return;
            } else if (!isLt5M) {
                ErrorMessageHandler(`Please check file maximum size of 5 MB.`, SUCCESS_FAILED.OTHERS_FAILED);
                return;
            }

            Modal.confirm({
                icon: <ExclamationCircleOutlined />,
                title: "Are you sure you want to upload this file?",
                width: "30%",
                onOk() {
                    var formData = new FormData();
                    formData.append("file", fileToUpload);

                    setIsUploading(true);
                    setIsLoading(true);
                    plainAxiosInstance
                        .post(APIs.RC_PRICE_SETTINGS.UPLOAD_PRICE_ALARM_SETTINGS, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then(res => {
                            if (res.status === 200) {
                                ErrorMessageHandler("Price alarm setting(s)", SUCCESS_FAILED.SUCCESS_UPLOAD_DATA);
                                setRunRefetchDataList(true);
                            } else {
                                ErrorMessageHandler(`${res.data}.`, SUCCESS_FAILED.OTHERS_FAILED);
                                setIsLoading(false);
                            }
                        })
                        .catch((error: any) => {
                            ErrorMessageHandler(`File upload failed${error.response.data.message ? `: ${error.response.data.message}` : "."}.`, SUCCESS_FAILED.OTHERS_FAILED);
                            setIsLoading(false);
                        })
                        .finally(() => setIsUploading(false));
                },
                onCancel() { },
            });
        } catch (error) {
            ErrorMessageHandler(`Error during uploading file. Please try again.`, SUCCESS_FAILED.OTHERS_FAILED);
        }
    };

    const validateSymbol = (_: any, value: any) => {
        if (!value) {
            return Promise.resolve();
        }
        const symbol = value.toLowerCase().trim();
        if (symbolsList && symbolsList.includes(symbol)) {
            return Promise.reject("Symbol already exists");
        }
        return Promise.resolve();
    };

    const onFormSubmit = () => {
        priceAlarmSettingForm
            .validateFields()
            .then(values => {
                setIsLoading(true);
                if (currEdit === InnerPageActionMode.CREATE_NEW) {
                    let existingRow: any = null;
                    data.find((currRow) => {
                        if (currRow && currRow.symbol && currRow.symbol.toLowerCase().trim() === values.symbol.toLowerCase().trim()) {
                            existingRow = currRow;
                        }
                    });

                    if (existingRow) {
                        // Update existing row
                        let copyData = JSON.parse(JSON.stringify(data));
                        let newData = copyData
                            .map((x: PriceAlarmSettingDetailsExtra) => {
                                return {
                                    ...existingRow,
                                    ...values,
                                    isCreateEdit: InnerPageActionMode.EDIT
                                };
                            })
                        setData(newData);
                        setIsLoading(false);
                        priceAlarmSettingForm.resetFields();
                        setCurrEdit(null);
                    } else {
                        // Add new row
                        let copyData = JSON.parse(JSON.stringify(data)),
                            addedData = [];
                        addedData.push(
                            {
                                ...values,
                                isCreateEdit: InnerPageActionMode.CREATE_NEW,
                                typeName: "Unknown",
                                serverUno: server,
                                serverName: rcPriceAlarmSettingServers?.find(serverItem => serverItem?.serverUno.toString() === server)?.name,
                            },
                            ...copyData
                        );
                        setData(addedData);
                        setIsLoading(false);
                        priceAlarmSettingForm.resetFields();
                        setCurrEdit(null);
                        ErrorMessageHandler(`New symbol added successfully.`, SUCCESS_FAILED.OTHERS_SUCCESS);
                    };
                } else {
                    let copyData = JSON.parse(JSON.stringify(data));
                    let newData = copyData
                        .map((x: PriceAlarmSettingDetailsExtra) => {
                            let editedData =
                                currEdit && x.newKey === currEdit.newKey
                                    ? {
                                        ...x,
                                        ...values,
                                        isCreateEdit:
                                            x.isCreateEdit === InnerPageActionMode.CREATE_NEW
                                                ? InnerPageActionMode.CREATE_NEW
                                                : InnerPageActionMode.EDIT,
                                    }
                                    : { ...x };
                            return editedData;
                        })
                    setData(newData);
                    setIsLoading(false);
                    priceAlarmSettingForm.resetFields();
                    setCurrEdit(null);
                };
            })
            .catch(errorInfo => {
                console.error("Failed to submit form: ", errorInfo);
            });
    };

    const handleReset = () => {
        setIsLoading(true);
        setIsResetting(true);
        setTimeout(() => {
            setIsLoading(false);
            setIsResetting(false);
            setData(oriData);
            setAbleToResetSave(false);
            setValidateError([]);
        }, 1000);
    };

    // #region Helper functions
    function timeToMinutes(time: string): number {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    };

    function minutesToTime(minutes: number): string {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
    };
    // #endregion

    const handleValidateBeforeSubmit = useCallback(() => {
        return new Promise<boolean>(resolve => {
            const errors: IValidateError[] = [];

            // Helper function to validate and combine time ranges
            const validateTimeRanges = (timeString: string): string | null => {
                // Remove all spaces from the input
                timeString = timeString.replace(/\s/g, "");

                // Split the string into individual ranges
                const ranges = timeString.split(";");

                // Validate each range
                const validRanges = ranges.filter(range => {
                    const [start, end] = range.split("-");
                    return /^([01]\d|2[0-3]|24):([0-5]\d)$/.test(start) && /^([01]\d|2[0-3]|24):([0-5]\d)$/.test(end);
                });

                if (validRanges.length !== ranges.length) {
                    return null; // Invalid format
                }

                // Convert ranges to minutes, sort, and combine
                let timeRanges = validRanges
                    .map(range => {
                        const [start, end] = range.split("-").map(timeToMinutes);
                        return { start, end };
                    })
                    .sort((a, b) => a.start - b.start);

                let combinedRanges = [timeRanges[0]];
                for (let i = 1; i < timeRanges.length; i++) {
                    let lastRange = combinedRanges[combinedRanges.length - 1];
                    if (timeRanges[i].start <= lastRange.end) {
                        lastRange.end = Math.max(lastRange.end, timeRanges[i].end);
                    } else {
                        combinedRanges.push(timeRanges[i]);
                    }
                };

                // Convert back to string format
                return combinedRanges.map(range => `${minutesToTime(range.start)}-${minutesToTime(range.end)}`).join(";");
            };

            if (data) {
                let newData: PriceAlarmSettingDetailsExtra[] = [];
                data.forEach((currRow: any) => {
                    const errorMessages: string[] = [];
                    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

                    days.forEach(day => {
                        const validatedTime = validateTimeRanges(currRow[day]);
                        if (validatedTime === null) {
                            errorMessages.push(`${day.charAt(0).toUpperCase() + day.slice(1)} time is invalid: ${currRow[day]}`);
                        } else {
                            // Update the node data with the combined time ranges
                            // currRow.setDataValue(day, validatedTime);

                            /**
                             *  this method below will cause duplicates in the table, need to think of other way
                             *  but this feature is just an optional feature
                             */
                            // let copyData = JSON.parse(JSON.stringify(data));
                            // newData = copyData
                            //     .map((x: PriceAlarmSettingDetailsExtra) => {
                            //         return {
                            //             ...currRow,
                            //             [day]: validatedTime,
                            //             isCreateEdit: InnerPageActionMode.EDIT,
                            //         };
                            //     });
                        };
                    });
                    if (errorMessages.length > 0) {
                        errors.push({ symbol: currRow.symbol, error: errorMessages });
                    };
                });
                // setData(newData);
            };

            errors.length > 0 && console.log("Errors found: ", errors);
            setValidateError(errors);
            resolve(errors.length === 0);
        });
    }, [data]);

    const onSaveAllData = useCallback(async () => {
        const isValid = await handleValidateBeforeSubmit();

        let tmpAddedData: any[] = [],
            tmpEditedData: any[] = [],
            tmpDeletedData: any[] = [];
        data.forEach((currRow: PriceAlarmSettingDetailsExtra) => {
            if (currRow.isCreateEdit === InnerPageActionMode.CREATE_NEW) {
                if (currRow.isDeleted === undefined || currRow.isDeleted === false) {
                    tmpAddedData.push(currRow);
                };
            } else if (currRow.isCreateEdit === InnerPageActionMode.EDIT) {
                if (currRow.isDeleted === true) {
                    tmpDeletedData.push(currRow);
                } else {
                    tmpEditedData.push(currRow);
                };
            } else if (currRow.isDeleted === true) {
                tmpDeletedData.push(currRow);
            };
        });
        const addedData = tmpAddedData.map(({ isCreateEdit, newKey, ...rest }) => rest);
        const editedData = tmpEditedData.map(({ isCreateEdit, newKey, ...rest }) => rest);
        const deletedData = tmpDeletedData.map(({ isCreateEdit, newKey, isDeleted, ...rest }) => rest);

        Modal.confirm({
            title: "Are you sure you want to save all of these modified settings ?",
            icon: <ExclamationCircleOutlined />,
            width: "35%",
            content: (
                <div>
                    <ul>
                        {addedData.length > 0 && <li key={"create"}>Creating {addedData.length} new setting(s).</li>}
                        {editedData.length > 0 && <li key={"update"}>Updating {editedData.length} existing setting(s).</li>}
                        {deletedData.length > 0 && <li key={"delete"}>Deleting {deletedData.length} existing setting(s).</li>}
                    </ul>
                    <p>Note: All your changes will affect the price alarm outage once saved.</p>
                </div>
            ),
            okText: "Confirm",
            okButtonProps: { loading: isSubmitting },
            onOk: () => {
                let promises: any[] = [];
                if (addedData.length + editedData.length > 0)
                    promises.push(
                        plainAxiosInstance.post(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_SETTINGS_DETAILS}`, [...addedData, ...editedData])
                    );
                if (deletedData.length > 0)
                    promises.push(plainAxiosInstance.delete(`${APIs.RC_PRICE_SETTINGS.GET_PRICE_ALARM_SETTINGS_DETAILS}`, { data: deletedData }));

                if (!isValid) {
                    ErrorMessageHandler(`Please correct the errors before submitting.`, SUCCESS_FAILED.OTHERS_FAILED);
                    return;
                } else {
                    setIsLoading(true);
                    setIsSubmitting(true);
                    Promise.all(promises)
                        .then(() => {
                            if (addedData.length + editedData.length > 0) ErrorMessageHandler("Price alarm setting(s)", SUCCESS_FAILED.SUCCESS_UPDATE_DATA);
                            if (deletedData.length > 0) ErrorMessageHandler("Price alarm setting(s)", SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                            setRunRefetchDataList(true);
                        })
                        .catch((error: any) =>
                            ErrorCatchValidator(error, (err: any) => {
                                ErrorMessageHandler("price alarm setting(s) list", SUCCESS_FAILED.FAILED_UPDATE_DATA, err);
                                console.log("Failed to update price alarm settings list: ", err);
                                setIsLoading(false);
                            })
                        )
                        .finally(() => setIsSubmitting(false));
                };
            },
            onCancel: () => { },
        });
    }, [data, handleValidateBeforeSubmit]);

    useEffect(() => {
        if (runRefetchDataList) {
            if (server && parseInt(server) !== (oriData.length > 0 && oriData[0].serverUno) && ableToResetSave) {
                Modal.confirm({
                    title: "Are you sure you want to search new settings?",
                    content: "Note: Unsaved changes will be lost after searching.",
                    onOk: () => {
                        setIsLoading(true);
                        getPriceAlarmSettings();
                        setRunRefetchDataList(false);
                    },
                    onCancel: () => setRunRefetchDataList(false),
                });
            } else {
                setIsLoading(true);
                getPriceAlarmSettings();
                setRunRefetchDataList(false);
            };
        }
        return () => { };
    }, [runRefetchDataList, server, oriData, ableToResetSave]);

    useEffect(() => {
        if (data.length !== oriData.length || differenceWith(oriData, data, isEqual).length !== 0) {
            setAbleToResetSave(true);
        }
        return () => {
            setAbleToResetSave(false);
        };
    }, [data, oriData]);

    return (
        <div className="price-alarm-setting-container">
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Price Alarm Settings</span>
                </div>
            </div>
            <div className="top-nav">
                <div className="left-filter">
                    <div className="servertype-filter">
                        <span style={{ paddingRight: "0.651vw" }}>Server Type :</span>
                        <Select
                            placeholder="Server Type"
                            style={{ width: "60%" }}
                            options={[
                                { label: "All", value: "all" },
                                { label: "New", value: "new" },
                                { label: "Old", value: "old" },
                            ]}
                            value={serverType}
                            onChange={value => {
                                setServerType(value);
                                setValidateError([]);
                            }}
                        />
                    </div>
                    <div className="server-filter">
                        <span style={{ paddingRight: "0.651vw" }}>Server :</span>
                        <Select
                            showSearch
                            //make search search with label, instead of the serverUno
                            optionFilterProp="label"
                            placeholder="Server"
                            style={{ width: "75%" }}
                            disabled={isLoadingServers}
                            options={rcPriceAlarmSettingServers
                                .map(server => {
                                    return { label: server.name, value: server.serverUno.toString(), order: server.order };
                                })
                                // sort by each item's order key if available, if order is null, put to the last
                                .sort((a, b) => {
                                    if (a.order && b.order) {
                                        return a.order - b.order;
                                    }
                                    if (a.order) {
                                        return -1;
                                    }
                                    if (b.order) {
                                        return 1;
                                    }
                                    return 0;
                                })}
                            value={server}
                            onChange={value => {
                                setServer(value);
                                setValidateError([]);
                            }}
                        />
                    </div>
                    <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        loading={isLoading}
                        disabled={isLoading || isLoadingServers || server === null}
                        onClick={() => setRunRefetchDataList(true)}
                    >
                        Search
                    </Button>
                </div>
                <div className="right-action" style={ableToResetSave ? {} : { display: "none" }}>
                    <Button
                        icon={<UndoOutlined />}
                        disabled={!ableToResetSave}
                        loading={isResetting}
                        onClick={handleReset}
                    >
                        Reset Changes
                    </Button>
                    <Button
                        icon={<SaveOutlined />}
                        type="primary" disabled={!ableToResetSave} loading={isSubmitting} onClick={() => onSaveAllData()}>
                        Save
                    </Button>
                </div>
            </div>
            {validateError?.length >= 1 && (
                <Space direction="vertical" style={{ width: "100%", border: "1px solid #f0f0f0", padding: "1rem" }}>
                    <Typography.Text strong type="danger">
                        Validation Errors
                    </Typography.Text>
                    {validateError.map((error, index) => (
                        <Space key={index} direction="vertical" style={{ width: "100%" }} size={1}>
                            <Typography.Text strong>{error.symbol}</Typography.Text>
                            <Space direction="vertical" size={0}>
                                {error.error.map((message, index) => (
                                    <Typography.Text key={index} type="danger">
                                        {message}
                                    </Typography.Text>
                                ))}
                            </Space>
                        </Space>
                    ))}
                </Space>
            )}
            {server !== null && data.length > 0 && <FlexiDataTable
                bordered
                rowKeyProperty="newKey"
                title={""}
                columns={columns}
                options={options}
                dataSource={data}
                callback={componentCallback}
                loading={isLoading}
            />}
            <Modal
                open={currEdit !== null}
                title={`${currEdit === InnerPageActionMode.CREATE_NEW ? "Create New" : "Edit"} Price Alarm Setting`}
                onCancel={() => {
                    // reset fields
                    setCurrEdit(null);
                    priceAlarmSettingForm.resetFields();
                }}
                footer={null}
                width={800}
                centered
            >
                <Form
                    form={priceAlarmSettingForm}
                    layout="vertical"
                    initialValues={initFormValue}
                /* onValuesChange={handleFormUpdate} */ requiredMark={true}
                    onFinish={onFormSubmit}
                    autoComplete="off"
                >
                    <Space style={{ width: "100%" }} direction="vertical" wrap size={24}>
                        <Space style={{ width: "100%" }} direction="vertical" wrap size={0}>
                            <Typography.Title level={5}>General Settings</Typography.Title>
                            <Row gutter={[8, 8]}>
                                <NormalInputField
                                    label="Symbol"
                                    name="symbol"
                                    symbols={symbolsList}
                                    disabled={currEdit !== InnerPageActionMode.CREATE_NEW}
                                    rules={
                                        [
                                            { required: true, message: REQUIRED_FIELD },
                                            { validator: currEdit === InnerPageActionMode.CREATE_NEW ? validateSymbol : () => Promise.resolve() }
                                        ]
                                    }
                                // value={priceAlarmSettingForm.getFieldValue("symbol")}
                                // onChange={value => priceAlarmSettingForm.setFieldsValue({ symbol: value })}
                                />
                                <NormalInputField
                                    label="Clean Symbol"
                                    name="cleanSymbol"
                                    disabled={currEdit !== InnerPageActionMode.CREATE_NEW}
                                // value={priceAlarmSettingForm.getFieldValue("cleanSymbol")}
                                // onChange={value => priceAlarmSettingForm.setFieldsValue({ cleanSymbol: value })}
                                />
                            </Row>
                        </Space>
                        <Space style={{ width: "100%" }} direction="vertical" wrap size={0}>
                            <Typography.Title level={5}>Time Settings</Typography.Title>
                            <Row gutter={[8, 8]}>
                                <TimeRangeInputField label="Monday" name="monday" />
                                <TimeRangeInputField label="Tuesday" name="tuesday" />
                                <TimeRangeInputField label="Wednesday" name="wednesday" />
                                <TimeRangeInputField label="Thursday" name="thursday" />
                                <TimeRangeInputField label="Friday" name="friday" />
                                <TimeRangeInputField label="Saturday" name="saturday" />
                                <TimeRangeInputField label="Sunday" name="sunday" />
                            </Row>
                        </Space>
                        <Space style={{ width: "100%" }} direction="vertical" wrap size={0}>
                            <Typography.Title level={5}>Alarm Trigger Time and Digits</Typography.Title>
                            <Row gutter={[8, 8]}>
                                {/* no price time, open no price, digit */}
                                <InputNumberField
                                    label="No Price Time"
                                    name="noPriceTime"
                                // value={priceAlarmSettingForm.getFieldValue("noPriceTime")}
                                // onChange={value => priceAlarmSettingForm.setFieldsValue({ noPriceTime: value })}
                                />
                                <InputNumberField
                                    label="Open No Price"
                                    name="openNoPrice"
                                // value={priceAlarmSettingForm.getFieldValue("openNoPrice")}
                                // onChange={value => priceAlarmSettingForm.setFieldsValue({ openNoPrice: value })}
                                />
                                <InputNumberField
                                    label="Digit"
                                    name="digit"
                                // value={priceAlarmSettingForm.getFieldValue("digit")}
                                // onChange={value => priceAlarmSettingForm.setFieldsValue({ digit: value })}
                                />
                            </Row>
                        </Space>
                        <Space style={{ width: "100%" }} direction="vertical" wrap size={0}>
                            <Typography.Title level={5}>Other Details</Typography.Title>
                            <Row gutter={[8, 8]}>
                                {/* show, open sound, from rc */}
                                <DropdownInputField
                                    label="Show"
                                    name="showStatus"
                                    options={[
                                        { label: "Show", value: 0 },
                                        { label: "Hide", value: 1 },
                                    ]}
                                />
                                <DropdownInputField
                                    label="Open Sound"
                                    name="alertStatus"
                                    options={[
                                        { label: "Open Sound", value: 1 },
                                        { label: "Mute", value: 0 },
                                    ]}
                                />
                                <DropdownInputField
                                    label="From RC"
                                    name="forceSession"
                                    options={[
                                        { label: "True", value: 1 },
                                        { label: "False", value: 0 },
                                    ]}
                                />
                            </Row>
                        </Space>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 8 }}>
                            {/* reset form */}
                            <Button
                                type="ghost"
                                onClick={() => {
                                    if (currEdit === InnerPageActionMode.CREATE_NEW) {
                                        priceAlarmSettingForm.resetFields();
                                    } else {
                                        let oriCurrRow = currEdit && oriData.find((x: PriceAlarmSettingDetailsExtra) => x.newKey === currEdit?.newKey);
                                        priceAlarmSettingForm.setFieldsValue(oriCurrRow);
                                    };
                                }}
                                style={{ marginRight: "auto" }}
                            >
                                Reset
                            </Button>
                            <Button
                                type="ghost"
                                onClick={() => {
                                    setCurrEdit(null);
                                    priceAlarmSettingForm.resetFields();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </div>
                    </Space>
                </Form>
            </Modal>
        </div>
    );
};

export default PriceAlarmSettings2;