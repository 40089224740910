import { Modal } from "antd";
import { GroupTransferModalCallbackKey } from "../../GroupTransferTool/components/grouptransferModal";
import { useCallback, useEffect, useState } from "react";
import { HTHListItemProps } from "./hthlist";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { SUCCESS_FAILED } from "@/constants";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";

interface HubToHubActivityLogModalProps {
    isModalVisible: boolean;
    callback: (type: number, data: any) => void;
    data: undefined | HTHListItemProps;
}

export enum HubToHubActivityLogModalCallbackKey {
    Close = 0,
}

const HubToHubActivityLogModal = (props: HubToHubActivityLogModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);

    const getActivityLog = (filterParam: any) => {
        if (filterParam) {
            setData([]);
            setIsLoading(true);
            plainAxiosInstance
                .get(APIs.RISK_TOOL.GET_HUB_TO_HUB_LISTING_ACTIVITY_LOG)
                .then((res: any) => {
                    if (res.data) {
                        setData(res.data && res.data.length > 0 ? res.data : []);
                    }
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("activity log", SUCCESS_FAILED.FAILED_LOAD_DATA, err))
                )
                .finally(() => setIsLoading(false));
        } else {
            setData([]);
        }
    };

    useEffect(() => {
        if (props.isModalVisible) {
            getActivityLog(props.data);
        }
    }, [props.isModalVisible]);

    return (
        <Modal
            style={{ top: 10 }}
            width={"40vw"}
            title={"Activity Log"}
            open={props.isModalVisible}
            okButtonProps={{ style: { display: "none" } }}
            onCancel={() => props.callback(GroupTransferModalCallbackKey.Close, null)}
            maskClosable={false}
        ></Modal>
    );
};

export default HubToHubActivityLogModal;
